import Head from 'next/head';
import React from 'react';

import FourOhFour from '@/components/FourOhFour';
import Layout from '@/components/layout';
import siteConfigQuery from '@/utils/queries/siteConfigQuery';
import { getClient } from '@/utils/sanity/sanity.server';
import { ThemeProvider, Themes } from '@/components/context/theme';

interface Props {
  siteConfig: any;
}

const Page404 = ({ siteConfig }: Props): JSX.Element => {
  if (!siteConfig) return null;

  return (
    <ThemeProvider theme={Themes.DARK}>
      <Layout siteConfig={siteConfig}>
        <Head>
          <title>404</title>
        </Head>
        <FourOhFour />
      </Layout>
    </ThemeProvider>
  );
};

interface getStaticPropsProps {
  preview: boolean;
}

export async function getStaticProps({
  preview = false,
}: getStaticPropsProps): Promise<Record<string, unknown>> {
  const siteConfig = await getClient(preview).fetch(siteConfigQuery, { slug: null });

  return {
    revalidate: 60,
    props: {
      siteConfig,
    },
  };
}

export default Page404;
